// Printing Tables: http://css-discuss.incutio.com/wiki/Printing_Tables

@import "bootstrap/variables";
@import "bootstrap/mixins";
@import "bootstrap/mixins/breakpoints";
@import "bootstrap/mixins/grid-framework";
@import "bootstrap/mixins/grid";

.wp-content *,
a,
*::before,
*::after {
	color:#000000 !important;
	text-shadow:none !important;
	box-shadow:none !important;
}

.pot-top-bar,
.pot-hero-1-nav,
.pot-section-sub-nav,
.sub-nav,
.side-bar-nav,
.pot-contacts-nav,
.pot-left-bar,
.nav-breadcrumbs,
.pot-section-image,
footer {
	display:none;
}

.hidden-print {
	display:none !important;
}

thead {
	display: table-header-group;
}

.pot-section,
.pot-fp-section-1-column,
.column-nav li,
.pot-contacts-list-1 section,
tr,
img {
	page-break-inside:avoid;
}

p,
h2,
h3 {
	orphans:3;
	widows:3;
}

h2,
h3 {
	page-break-after:avoid;
}

a,
a:visited {
	text-decoration:underline;
}

// Make grid for print media

$print-breakpoints: (
	md: map-get($grid-breakpoints, md)
);
$columns: $grid-columns;

@each $breakpoint in map-keys($print-breakpoints) {
	$infix: breakpoint-infix($breakpoint, $print-breakpoints);

	@for $i from 1 through $columns {
		.col#{$infix}-#{$i} {
			@include make-col($i, $columns);
		}
	}

	@each $modifier in (pull, push) {
		@for $i from 0 through $columns {
			.#{$modifier}#{$infix}-#{$i} {
				@include make-col-modifier($modifier, $i, $columns)
			}
		}
	}

	// `$columns - 1` because offsetting by the width of an entire row isn't possible
	@for $i from 0 through ($columns - 1) {
		@if not ($infix == "" and $i == 0) { // Avoid emitting useless .offset-xs-0
			.offset#{$infix}-#{$i} {
				@include make-col-modifier(offset, $i, $columns)
			}
		}
	}
}

// Section elements ('front-page.php' & 'section-front-page.php')

.pot-section-content {
	flex-basis:100%;
	max-width:100%;
	margin-left:0;
}
